<template>
  <v-container fluid>

    <card :title="$t('models.region.selves')">
      <grid-view ref="grid" :headers="headers" model="region" endpoint="/region">
        <template v-slot:item._actions="{ item }">
          <v-btn @click="viewModel(item)" icon small>
            <v-icon small>mdi-eye</v-icon>
          </v-btn>
          <v-btn @click="editModel(item)" icon small>
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click="deleteModel(item)" icon small>
            <v-icon x-small>fa fa-trash</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.name="{ item }">
          {{ translate(item.name) }}
        </template>
      </grid-view>
    </card>

    <!-- view dialog -->
    <v-dialog v-model="viewDialog" max-width="600">
      <v-card v-if="model">
        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-0">
              {{ translate(model.name) }}
            </h3>
            <div>{{ model }}</div>
          </div>
        </v-card-title>
        <v-card-actions>
          <v-btn text @click="editModel(model)">{{ $t('actions.edit') }}</v-btn>
          <v-spacer/>
          <v-btn color="primary" @click="viewDialog = false">{{ $t('actions.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit dialog -->
    <v-dialog v-model="editDialog" max-width="600">
      <Form :regionId="editedModelId" v-if="editDialog" @save="onDialogSave"/>
    </v-dialog>

  </v-container>
</template>

<script>
import {Vue, Component} from "vue-property-decorator";
import Form from "@/pages/region/Form";
import formatter from '@/mixins/formatter';
import GridView from "@/components/GridView";
import Card from "@/components/Card";
import {setPageTitle} from "@/utils/meta";

@Component({components: {GridView, Card, Form}, mixins: [formatter]})
export default class RegionIndex extends Vue {

  viewDialog = false;
  editDialog = false;
  model = null;
  editedModelId = null;

  created() {
    setPageTitle(this.$i18n.t('models.region.selves'));
  }

  get headers() {
    return [
      {text: '', value: '_actions',},
      {text: this.$i18n.t('models.region.self'), value: 'name', sort: this.i18nSort},
      {text: this.$i18n.t('models.region.code'), value: 'code',},
    ];
  }

  viewModel(model) {
    this.$api.get(`region/${model.id}`).then(res => {
      this.model = res.data;
      this.viewDialog = true;
    });
  }

  editModel(region) {
    this.editedModelId = region.id;
    this.editDialog = true;
  }

  deleteModel(model) {
    this.$root.$confirm.open('Delete model', 'Are you sure you want to delete this model ?', {}).then((confirm) => {
      if (confirm) {
        this.$api.delete(`region/${model.id}`).then(() => {
          this.$refs.grid.getModels();
        }).catch(() => {
          alert("Unable to remove model");
        });
      }
    });
  }

  onDialogSave(model) {
    this.$refs.grid.getModels(model.id);
    this.editDialog = false;
    this.editedModelId = null;
  }

}
</script>
