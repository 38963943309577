<template>
  <div v-if="region">
    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <v-form @submit.stop.prevent="handleSubmit(onSubmit)">
        <v-card outlined>
          <v-card-title v-if="region.id">Editing {{ translate(region.name) }}</v-card-title>
          <v-card-title v-else>Creating new region</v-card-title>
          <v-card-text>
            <m-field v-model="region.code" m="region" a="code" />
            <m-field v-model="region.name" m="region" a="name" i18n />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn type="submit" color="primary">{{$t('actions.save')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import ImageUploader from 'vue-image-upload-resize';
import formatter from "@/mixins/formatter";

@Component({components: {ImageUploader}, mixins: [formatter]})
export default class RegionForm extends Vue {

  @Prop() regionId;

  region = null;

  mounted() {
    // get or create region object
    if (this.regionId) {
      this.$api.get(`region/${this.regionId}`).then(j => {
        this.region = j.data;
      });
    } else {
      this.region = {
        name: {},
      }
    }
  }

  onSubmit() {
    const call = this.regionId
      ? this.$api.patch(`region/${this.regionId}`, this.region)
      : this.$api.post(`region`, this.region)

    call.then(j => {
      this.$emit('save', j.data);
    }).catch(err => {
      if (err.response) this.$refs.form.setErrors(err.response.data);
      this.$snack.validationError(err.response.data)
    });
  }

  get rules() {
    return this.$store.state.rules;
  }

}
</script>

<style>
#fileInput {
  display: none;
}
h2 {
  margin: 1em 0;
}
</style>
